import { datadogLogs, StatusType } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

export function initializeUserMonitor() {
  const { VITE_APP_RELEASE, VITE_APP_TARGET } = import.meta.env;

  if ("Cypress" in window || VITE_APP_TARGET === "local") {
    return;
  }

  try {
    datadogRum.init({
      applicationId: "8d5cdf15-a997-4f74-bcfb-4b51fce4e6c1",
      clientToken: "pub2ad99c7baa0fba02763945b08407ccdb",
      site: "datadoghq.com",
      service: "online-bol-web",
      version: VITE_APP_RELEASE,
      env: VITE_APP_TARGET,
      sessionSampleRate: 5,
      sessionReplaySampleRate: 0,
      traceSampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      allowedTracingUrls: [
        "https://carrier.superdispatch.com",
        "https://staging.carrier.superdispatch.org",
      ],
    });
  } catch (error) {
    logWarning("Failed to initialize DataDog RUM", { error });
  }
}

const urlMatchers = ["https://c.us.heap-api.com"];

const errorMatchers = [
  "Failed to load Analytics.js",
  "Load failed",
  "Script error.",
];

const ignoredErrorMatchers = [
  "ResizeObserver loop limit exceeded",
  "Object Not Found Matching Id",
  "Can't find variable: gmo",
];

const errorKindMatchers = ["InvalidStateError", "MapboxError"];

if (!("Cypress" in window) && process.env.NODE_ENV === "production") {
  const { VITE_APP_RELEASE, VITE_APP_TARGET } = import.meta.env;

  datadogLogs.init({
    clientToken: "pubc5418a39f9f1055ee2c7a98996ccb298",
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    version: VITE_APP_RELEASE,
    env: VITE_APP_TARGET,
    service: "online-bol-web",
    forwardConsoleLogs: ["error"],
    beforeSend(log) {
      //Skip all "Failed to fetch" errors
      if (log.http?.status_code === 0) {
        return false;
      }

      if (log.origin === "console") {
        log.status = "warn";
        return;
      }

      //silence third-party errors
      if (log.error?.stack?.includes("<anonymous>")) {
        log.status = "warn";
        return;
      }

      //skip logging loading errors of specific URLs
      if (log.http) {
        for (const urlPart of urlMatchers) {
          if (log.http.url.startsWith(urlPart)) {
            return false;
          }
        }
      }

      //skip logging specific kind of errors
      if (log.error) {
        for (const matcher of errorKindMatchers) {
          if (log.error.kind === matcher) {
            log.status = "warn";
            return;
          }
        }
      }

      for (const matcher of ignoredErrorMatchers) {
        if (log.message.includes(matcher)) {
          return false;
        }
      }

      for (const matcher of errorMatchers) {
        if (log.message.includes(matcher)) {
          log.status = "warn";
          return;
        }
      }

      return undefined;
    },
  });
}

export function getDatadogLogger() {
  return datadogLogs.logger;
}

export interface CaptureExceptionOptions {
  extraInfo?: Record<string, unknown>;
  severity?: StatusType;
}

export function logError(
  error: unknown,
  source: string,
  { extraInfo, severity = "error" }: CaptureExceptionOptions = {},
) {
  const logger = getDatadogLogger();
  logger.setLevel(severity);
  logger.addContext("source", source);
  logger.addContext("extraInfo", extraInfo);
  logger.addContext("module-name", "online-bol");

  if (typeof error === "string") {
    logger.log(error, {}, severity);
  } else if (error instanceof Error) {
    logger.error(error.message, error);
  } else {
    logger.log(JSON.stringify(error), {}, severity);
  }

  if (
    error instanceof Error &&
    (error.name === "ChunkLoadError" ||
      error.message === "Unexpected token '<'")
  ) {
    setTimeout(() => {
      requestForceReload();
    }, 2000);
  }
}

function requestForceReload() {
  if (
    // eslint-disable-next-line no-alert
    window.confirm(
      "Failed to load required assets. This can happen either due to bad internet connection or a new version of the website. Reload the page?\n" +
        "(Clear your browser cache and reload the page manually if this error occurs again)",
    )
  ) {
    window.location.reload();
  }
}

export function logWarning(
  message: string,
  extraInfo?: Record<string, unknown>,
) {
  const logger = getDatadogLogger();
  logger.warn(message, { extraInfo });
}
